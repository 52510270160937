import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

const Container = tw.div`relative bg-white text-gray-900 px-4 md:px-32`;
const Content = tw.div`max-w-screen-xl mx-auto pt-4 md:pt-16 md:pb-8`
const FiveColumns = tw.div`flex flex-wrap justify-center lg:justify-start`;

const Column = tw.div`flex flex-col justify-center w-full md:w-3/4 mb-2 md:mb-0 text-sm sm:text-base text-center md:text-left`;
const CompanyColumn = tw.div`text-center md:text-left mb-4  md:mb-16 lg:mb-0 w-full lg:w-1/4`;

const LogoContainer = tw.div`flex items-center justify-center lg:justify-start`;
const DisclaimerHeading = tw.h5`px-2 md:px-8 uppercase font-light text-center lg:text-left`;
const DisclaimerInfoRow = tw.div`w-full pb-0 text-sm font-normal flex flex-col justify-center items-center lg:items-start px-2 md:px-8 py-2`

const LogoImg = tw.img`h-20 md:h-12 w-auto`;

const CompanyAddress = tw.p`mt-2 md:mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;


const CopyrightAndCompanyInfoRow = tw.div`pb-0 text-sm font-normal flex flex-col sm:flex-row justify-center items-center p-2 md:p-8 text-center`
const CopyrightNotice = tw.div``

const Divider = tw.div`my-1 border-b-2 border-gray-100`
export default () => {
  return (
    <Container>
      <Content>
        <FiveColumns>
          <CompanyColumn>
              <LogoContainer>
                <LogoImg src="https://cdn.shopify.com/s/files/1/0508/0625/9870/files/ProLon_Logo_-_Large_ltgreen_256x256_49d35963-d684-49a0-84bc-8124bcd3308b_190x.png?v=1604100547" />
              </LogoContainer>
            <CompanyAddress>
                Health Care Providers interested in authorizing ProLon® for their patients can Register Here.
            </CompanyAddress>
          </CompanyColumn>
          <Column>
            <DisclaimerHeading>DISCLAIMERS</DisclaimerHeading>
            <DisclaimerInfoRow><>* In a clinical study, ProLon is shown over three cycles to help individuals lose an average of 5.7 lbs and 1.6 inches off their waist circumference. <strong>Individual Results May Vary</strong><br className="hidden md:flex"/>The ingredients in ProLon are considered generally recognized as safe (GRAS). ProLon is not intended to diagnose, treat, cure or prevent any disease and has not been evaluated as such by the FDA.</></DisclaimerInfoRow>
          </Column>
        </FiveColumns>
        <Divider/>
        <CopyrightAndCompanyInfoRow>
          <>© 2021 ProLon® Fast - 8000 Beverly Blvd. Los Angeles, California 90048 • (323) 580.6855<br />Powered by L-Nutra Inc. - Science Based Products that Repair and Rejuvenate the Body.</>
        </CopyrightAndCompanyInfoRow>
      </Content>
    </Container>
  );
};
